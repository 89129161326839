<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="save", v-if="mmpAccount")

			b-tabs(content-class="mt-3", pills, card)
				b-tab(title="Main", style="padding: 0")
					.row
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label MMP Account Information <span v-if="mmpAccount.id">({{ mmpAccount.id }})</span>
									.card-toolbar
								.card-body
									.row
										.col-sm-6
											.form-group
												label Name
												input.form-control(type="text", v-model="mmpAccount.name")
										.col-sm-6
											.form-group
												label Account Identifier
												input.form-control(type="text", v-model="mmpAccount.identifier")
									.row
										.col-sm-6
											.form-group
												label Account Username
												input.form-control(type="text", v-model="mmpAccount.username")
									.row
										.col-sm-6
											.form-group
												label MMP
												select.form-control(v-model="mmpAccount.mmp")
													option(v-for="t in mmpOptions", :value="t.v") {{ t.t }}
										.col-sm-6
											.form-group
												label Type
												select.form-control(v-model="mmpAccount.type")
													option(v-for="t in typeOptions", :value="t.v") {{ t.t }}
									table.table(v-if="selectedIntegration")
										thead
											tr
												th(width="30%") Key
												th Value
										tbody
											tr(v-for="o in selectedIntegration.credentials")
												td {{ o.key }}
												td
													input.form-control(type="text", v-model="mmpAccount.credentials[o.key]")
									.form-group
										label Comments
										textarea.form-control(rows="5", v-model="mmpAccount.comments")

				b-tab(title="Assets", style="padding: 0")
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Assets
							.card-toolbar
						.card-body
							table.table.table-bordered
								thead
									tr
										th ID
										th Platform
										th Icon
										th Name
										th Sync Raw
										th Sync Stats
										th Actions
								tbody
									tr(v-for="(o, i) in mmpAccount.assets")
										td {{ o.asset.id }}
										td {{ o.asset.platform }}
										td
											div.app-icon-container
												img(v-if="o.asset.icon", :src="o.asset.icon")
										td {{ o.asset.name }}
											div(v-if="o._view_events")
												.row
													.col-sm-4 Events
														table.table.table-compact.table-selected-events
															thead
																tr
																	th #
																	th Key
																	th.actions
																		button.btn.btn-action.btn-outline-primary(type="button", @click="addEvent(o)")
																			i.la.la-plus
															tbody
																tr(v-for="(f, j) in o.events")
																	td e{{ j+1 }}
																	td
																		input.form-control(v-model="f.key")
																	td.actions
																		button.btn.btn-action.btn-outline-danger(type="button", @click="o.events.splice(j, 1)")
																			i.la.la-minus

													.col-sm-8
														table.table-compact.table-mini
															tbody
																tr(v-for="f in o.all_events")
																	td {{ f.key }}
																	td {{ f.label }}
										td
											b-checkbox(switch="", v-model="o.sync_raw")
											div(v-for="r in reportTypes.raw")
												b-checkbox(v-model="o.sync_reports[r.v]")  {{ r.t }}
										td
											b-checkbox(switch="", v-model="o.sync_stats")
											div(v-for="r in reportTypes.aggregated")
												b-checkbox(v-model="o.sync_reports[r.v]") {{ r.t }}
										td.actions
											button.btn.btn-action.btn-outline-danger(type="button", @click="mmpAccount.assets.splice(i, 1)")
												i.la.la-trash
											button.btn.btn-action.btn-outline-secondary(type="button", @click="toggleViewEvents(o)")
												i.la(:class="{'la-caret-right': !o._view_events, 'la-caret-down': o._view_events}")
							.form-group
								label Add Asset
								v-select.select-asset(v-model="selectedAsset", :options="assetOptions", label="name", @option:selected="onSelectAsset")
									template(v-slot:option="option")
										span.select-asset-option
											span.select-asset-icon
												img(:src="option.icon || ''")
											span.select-asset-text
												span.select-asset-name {{ option.name }}
												span.select-asset-package {{ option.package_id }}



</template>
<style lang="scss">
.table-selected-events {
	th.actions,
	td.actions {
		width: 30px;
		text-align: center;
	}
}
.table-mini {
	td {
		padding: 2px;
	}
}

</style>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import store from "@/core/services/store";
import {OVERRIDE_PAGE_LAYOUT_CONFIG, SET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import HtmlClass from "@/core/services/htmlclass.service";

async function loadRoute(vm, to, from, next){

	let mmpAccount = {};
	if (to.name === 'mmp-account-edit') {
		let entityId = +to.params.id;
		try {
			mmpAccount = await Vue.$dwData.mmpAccount.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load MMP Account');
			return next(false);
		}
	} else {
		mmpAccount = Vue.$dwData.mmpAccount.newInstance();
	}


	let integrations = await Vue.$dwData.mmpAccount.getIntegrations();
	let assetResult = await Vue.$api.get('/assets/getList', {page: 1, limit: 1000});
	mmpAccount.assets.forEach(a => {
		Vue.set(a, '_view_events', false);
	});


	return vm => {
		vm.mmpAccount = mmpAccount;
		vm.integrations = integrations;
		vm.assetOptions = assetResult.records;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'MMPAccountForm',
	data(){
		return {
			busy: true,
			mmpAccount: null,
			integrations: [],
			typeOptions: Vue.$dwData.general.typeOptions,
			mmpOptions: Vue.$dwData.general.mmpOptions,
			assetOptions: [],
			selectedAsset: null,
			reportTypes: {
				raw: Vue.$dwData.general.reportTypeOptions,
				aggregated: Vue.$dwData.general.aggregatedReportTypeOptions
			}
		};
	},
	computed: {
		selectedIntegration(){
			if (!this.mmpAccount || !this.mmpAccount.mmp){
				return null;
			}
			for (let i=0; i<this.integrations.length; i++){
				if (this.integrations[i].id === this.mmpAccount.mmp){
					return this.integrations[i];
				}
			}
			return null;
		}
	},
	methods: {
		addEvent(o){
			o.events.push({
				key: ''
			});
		},
		toggleViewEvents(r){
			r._view_events = !r._view_events;
		},
		onSelectAsset(){
			if (!this.selectedAsset){
				return;
			}
			for (let i=0; i<this.mmpAccount.assets.length; i++){
				if (this.mmpAccount.assets[i].asset_id === this.selectedAsset.id){
					return;
				}
			}
			this.mmpAccount.assets.push({
				asset_id: this.selectedAsset.id,
				asset: this.selectedAsset,
				_view_events: false,
				sync_stats: false,
				events: [],
				sync_raw: false,
				sync_reports: {},
				all_events: [],
			});
			this.selectedAsset = null;
		},

		async save(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.mmpAccount);
			try {
				let resp = await this.$api.post('mmp-accounts/save', data);
				this.busy = false;
				this.$notify.success('MMP Account has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'mmp-account-list'});
				} else {
					this.mmpAccount = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'MMP Accounts', name: 'mmp-account-list'},
			{title: this.mmpAccount?.id ? 'Edit MMP Account' : 'Add MMP Account'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'mmp-account-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.save();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.save(true);
				}
			}
		]);
	},
}

</script>
